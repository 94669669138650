
const handleEmailSubmission = async (email) => {
    try {
        const response = await fetch('https://adscity.net/api/subscribe', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (!response.ok) {
            throw new Error('Erreur lors de l\'inscription.');
        }

        const result = await response.json();
        console.log('result: ', result);
    } catch (error) {
        console.error('Erreur lors de la souscription :', error);
    }
};


export { handleEmailSubmission };