import React from 'react';
import ReactWhatsapp from 'react-whatsapp';
import { WhatsAppIcon } from '../config';
import '../styles/WhatMe.css';

export default function WhatMe({ number, message }) {
    return (
        <div className='what-me'>
            <ReactWhatsapp title='Ecrire sur WhatsApp'
                style={{ background: 'transparent', border: 'none', cursor: 'pointer' }}
                number={number}
                message={message}
            >
                <img style={{ width: '30px', height: '30px' }} src={WhatsAppIcon} alt="" />
            </ReactWhatsapp>
        </div>
    );
};