import React, { useEffect, useState } from 'react';
import { AppIcon, AppLogo } from '../config';
import WhatMe from './WhatMe';
import { handleEmailSubmission } from '../func';
import '../styles/ComingSoonPage.css';

export default function ComingSoonPage() {
    const [email, setEmail] = useState('');
    const [toast, setToast] = useState({ type: '', message: '' });
    const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const countdownDate = new Date('2025-01-01T00:00:00').getTime();
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval);
            } else {
                setTimeLeft({ days, hours, minutes, seconds });
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    // Validation simple d'email
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };


    // Gérer la soumission du formulaire
    const handleSubscribe = async (e) => {
        e.preventDefault();
        setToast({});

        if (!email) {
            setToast({ type: 'error', message: 'Veuillez remplir le champ email' });
            return;
        };

        if (!validateEmail(email)) {
            setToast({ type: 'error', message: 'Veuillez entrer une adresse email valide.' });
            return;
        }

        try {
            // Appeler une API pour enregistrer l'email dans la base de données ou envoyer un email
            // await subscribeEmail(email); // Fonction backend fictive

            const userEmail = await handleEmailSubmission(email);
            if (userEmail === true) {
                setToast({ type: 'success', message: 'Souscription réussie' });
                setEmail('');
            } else {
                setToast({ type: 'error', message: 'Souscription échouée' });
                setEmail('');
            }
        } catch (error) {
            setToast({ type: 'error', message: 'Une erreur est survenue lors de l\'inscription.' });
            console.error('Erreur d\'inscription:', error);
        }
    }

    return (
        <div className="coming-soon-container">
            <img src={AppLogo} title='AdsCity' alt="" className="adscity-logo" />
            <p className="coming-soon-text">Nous arrivons très bientôt !</p>
            <div className="countdown">
                <div className="countdown-item">
                    <span>{timeLeft.days}</span>
                    <span>Jours</span>
                </div>
                <div className="countdown-item">
                    <span>{timeLeft.hours}</span>
                    <span>Heures</span>
                </div>
                <div className="countdown-item">
                    <span>{timeLeft.minutes}</span>
                    <span>Minutes</span>
                </div>
                <div className="countdown-item">
                    <span>{timeLeft.seconds}</span>
                    <span>Secondes</span>
                </div>
            </div>
            <p className="stay-tuned">Restez informé !</p>
            <form onSubmit={handleSubscribe} className="email-subscription">
                <input type="email" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                <button type="submit">S'abonner</button>
            </form>
            {toast.type === 'error' && <p className="error-message">{toast.message}</p>}
            {toast.type === 'success' && <p className="success-message">{toast.message}</p>}

            <div className="foot">
                <img src={AppIcon} alt="" className="footer-logo" />
                <span>Publiez, Vendez, Echangez</span>
            </div>
            <WhatMe
                number={process.env.REACT_APP_WHATSAPP_PHONE_NUMBER}
                message={"Salut, j'ai besoin de renseignements"}
            />
        </div>
    );
};
